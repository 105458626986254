<template>
  <div class="minimal-layout">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.minimal-layout {
  min-height: 100vh;
  background: #fff;
  display: flex;
}
</style>
